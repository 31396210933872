import {shouldReduceMotion} from './animate-on-scroll'

class SlideShow extends HTMLElement {
  bullets: NodeListOf<Element>
  slideIds: string[]
  interval: number | null
  current: number
  intervalTime: number
  currentSlideId: string | null
  swipeArea: HTMLElement | null
  touchStartX: number
  touchEndX: number
  swipeLengthMod: number

  /* eslint-disable-next-line custom-elements/no-constructor */
  constructor() {
    super()

    const autoplayAttribute = this.getAttribute('data-slide-show-autoplay')
    const threshold = this.getAttribute('data-threshold') || '0.2'
    this.intervalTime = 6000

    this.bullets = this.querySelectorAll('.js-slide-show-bullet[aria-controls]')
    this.slideIds = []
    this.interval = null
    this.current = 0
    this.currentSlideId = null

    this.swipeArea = this.querySelector('.js-slide-show-swipe-area')
    this.touchStartX = 0
    this.touchEndX = 0
    this.swipeLengthMod = 20

    if (autoplayAttribute && !shouldReduceMotion(this)) {
      const intervalAttributeParsed = parseInt(autoplayAttribute, 10)
      if (!isNaN(intervalAttributeParsed)) this.intervalTime = intervalAttributeParsed

      // NOTE: each GitHubslide-show instance will have it's own IntersectionObserver instance - if there are more than one on the page, consider global observer
      const observer = new IntersectionObserver(
        entries => {
          for (const entry of entries) {
            const target: SlideShow = <SlideShow>entry.target
            entry.isIntersecting ? target.play() : target.pause()
          }
        },
        {threshold: Number(threshold)},
      )

      observer.observe(this)
    }

    for (let i = 0; i < this.bullets.length; i++) {
      const bullet = this.bullets[i]!
      const slideId = bullet.getAttribute('aria-controls')
      const currentBullet = bullet.getAttribute('aria-selected')

      if (slideId) this.slideIds.push(slideId)

      if (currentBullet === 'true') {
        this.current = i
        this.currentSlideId = slideId
      }

      bullet.addEventListener('click', this.bulletOnClick.bind(this))
      bullet.addEventListener('keydown', this.bulletOnKeydown.bind(this) as EventListener, false)
    }

    if (this.swipeArea) {
      this.swipeArea.addEventListener('touchstart', this.onTouchStart.bind(this), {passive: true})
      // eslint-disable-next-line github/require-passive-events
      this.swipeArea.addEventListener('touchend', this.onTouchEnd.bind(this))
    }
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onTouchStart(e: TouchEvent): void {
    this.touchStartX = e.changedTouches[0]!.screenX
  }

  /* eslint-disable-next-line custom-elements/no-method-prefixed-with-on */
  onTouchEnd(e: TouchEvent): void {
    this.touchEndX = e.changedTouches[0]!.screenX

    if (this.touchEndX < this.touchStartX - this.swipeLengthMod) {
      this.pause()
      this.nextSlide()
    }

    if (this.touchEndX > this.touchStartX + this.swipeLengthMod) {
      this.pause()
      this.prevSlide()
    }
  }

  pause(): void {
    if (this.interval) window.clearInterval(this.interval)
  }

  play(): void {
    this.pause()
    this.interval = window.setInterval(this.nextSlide.bind(this), this.intervalTime)
  }

  nextSlide(): void {
    this.current++

    if (this.current > this.slideIds.length - 1) {
      this.current = 0
    }

    this.goToSlide(this.slideIds[this.current]!)
  }

  prevSlide(): void {
    this.current--

    if (this.current < 0) {
      this.current = this.slideIds.length - 1
    }

    this.goToSlide(this.slideIds[this.current]!)
  }

  bulletOnClick(e: Event): void {
    e.preventDefault()
    const target = e.target as HTMLElement
    const nextSlideId = target?.getAttribute('aria-controls')
    if (nextSlideId) this.goToSlide(nextSlideId)
    this.pause()
  }

  bulletOnKeydown(e: KeyboardEvent): void {
    const target = e.target as HTMLElement
    const tablist = target.closest('[role="tablist"]')
    const keys: {[key: string]: () => void} = {}

    keys['ArrowLeft'] = () => this.prevSlide()
    keys['ArrowRight'] = () => this.nextSlide()

    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
    const handler = keys[e.key]
    if (!handler) return
    this.pause()
    handler()
    ;(tablist?.querySelector('[aria-selected="true"]') as HTMLElement)?.focus()
  }

  goToSlide(slideId: string): void {
    const nextSlide = this.querySelector(`.js-slide-show-slide[id="${slideId}"]`)
    const nextBullets = this.querySelectorAll(`.js-slide-show-bullet[aria-controls="${slideId}"]`)
    const currentSlide = this.querySelector('.js-slide-show-slide:not([aria-hidden="true"])')
    const currentBullets = this.querySelectorAll('.js-slide-show-bullet[aria-selected="true"]')

    currentSlide?.setAttribute('aria-hidden', 'true')

    for (let i = 0; i < currentBullets.length; i++) {
      const goto = currentBullets[i]!
      goto.setAttribute('aria-selected', 'false')
      goto.setAttribute('tabindex', '-1')
    }

    nextSlide?.removeAttribute('aria-hidden')

    for (let i = 0; i < nextBullets.length; i++) {
      const goto = nextBullets[i]!
      goto.setAttribute('aria-selected', 'true')
      goto.removeAttribute('tabindex')
    }
  }
}

if (window && 'customElements' in window && !window.customElements.get('slide-show')) {
  window.customElements.define('slide-show', SlideShow)
}
